import { promiseTimeout } from '@vueuse/core';

export function useMoonPay() {
  const { $moonpay, $rollbar, } = useNuxtApp();
  const config = useRuntimeConfig().public;

  const userStore = useUserStore();
  const { getUserProfile, } = userStore;
  const { userData, userProfile, } = storeToRefs(userStore);

  const uistore = useUiStore();
  const { toastNotification, } = storeToRefs(uistore);

  const locationStore = useLocationStore();
  const { ip, } = storeToRefs(locationStore);
  const { fetchUserIPLocation, } = locationStore;

  const depositAccountstore = useDepositAccountStore();
  const { getSignedURL, } = depositAccountstore;

  const isMoonPayLoading = ref(false);
  const moonpayUserLocationData = ref();

  async function fetchMoonpayFiatCurrencies() {
    try {
      const response = await useFetch('https://api.moonpay.com/v3/currencies', {
        method: 'GET',
        headers: { accept: 'application/json', },
        query: {
          apiKey: config.MOONPAY_KEY,
        },
      });
      return response.data._value.filter(currency => currency.type !== 'crypto');
    } catch (error) {
      console.log('fetchMoonpayFiatCurrencies failed', error);
      // $rollbar.error('fetchMoonpayFiatCurrencies failed', error);
    }
  }

  async function fetchMoonpayCryptoCurrencies() {
    try {
      const response = await useFetch('https://api.moonpay.com/v3/currencies', {
        method: 'GET',
        headers: { accept: 'application/json', },
        query: {
          apiKey: config.MOONPAY_KEY,
        },
      });

      await fetchMoonpayUserLocation();

      return response.data._value
      .filter(currency => currency.type === 'crypto')
      .filter(currency => !currency.isSuspended)
      .filter(currency => !currency.notAllowedUSStates.find(state => state === moonpayUserLocationData.value.state))
      .filter(currency => !currency.notAllowedCountries.find(state => state === moonpayUserLocationData.value.country));
    } catch (error) {
      console.log('fetchMoonpayCryptoCurrencies failed', error);
      // $rollbar.error('fetchMoonpayCryptoCurrencies failed', error);
    }
  }

  async function fetchMoonpayUserLocation() {
    try {
      await fetchUserIPLocation();
      const response = await useFetch('https://api.moonpay.com/v3/ip_address', {
        method: 'GET',
        headers: { accept: 'application/json', },
        query: {
          apiKey: config.MOONPAY_KEY,
          ipAddress: ip.value,
        },
      });

      moonpayUserLocationData.value = response.data.value;
    } catch (error) {
      console.log('fetchMoonpayUserLocation failed', error);
      // $rollbar.error('fetchMoonpayUserLocation failed', error);
    }
  }

  /**
   * https://dev.moonpay.com/reference/getbuyquote
   */
  async function fetchMoonpayBuyFiatQuote(currencyCode, crpytoCode, value, type) {
    if (!crpytoCode || !currencyCode) {
      return;
    }
    try {
      const response = await useFetch(`https://api.moonpay.com/v3/currencies/${crpytoCode.toLowerCase()}/buy_quote`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
        },
        query: {
          apiKey: config.MOONPAY_KEY,
          baseCurrencyCode: currencyCode, // FIAT
          baseCurrencyAmount: type === 'fiat' ? value : undefined, // REQUIRED if quoteCurrencyAmount is not provided.
          quoteCurrencyAmount: type === 'crypto' ? value : undefined, // REQUIRED if baseCurrencyAmount is not provided.
          extraFeePercentage: 0, // 0 >= x <= 10
          paymentMethod: 'credit_debit_card',
          areFeesIncluded: false,
        },
      });

      if (response.error?.value) {
        return {
          error: true,
          data: response.error.value.data,
        };
      } else {
        return response.data._value;
      }
    } catch (error) {
      console.log('fetchMoonpayBuyFiatQuote failed', error);
      $rollbar.error('fetchMoonpayBuyFiatQuote failed', error);
      return error;
    }
  }

  async function handleStartMoonpayPayment(address, value = undefined, fiatCurrency = undefined, cryptoCurrency = undefined) {
    try {
      isMoonPayLoading.value = true;
      if (!userProfile.value?.email) {
        await getUserProfile();
      }
      const moonpay = $moonpay.init({
        baseCurrencyAmount: value,
        baseCurrencyCode: fiatCurrency, // Fiat
        colorCode: '#1475e1',
        currencyCode: cryptoCurrency, // Crypto
        email: userProfile.value?.email ? userProfile.value.email : '',
        externalTransactionId: userData.value?.id,
        paymentMethod: value ? 'credit_debit_card' : undefined,
        showWalletAddressForm: true,
        theme: 'dark',
        walletAddress: address,
      });

      const url = moonpay.generateUrlForSigning();
      const response = await getSignedURL(url);
      const signature = new URL(response).searchParams.get('signature');

      moonpay.updateSignature(signature);
      moonpay.show();
    } catch (error) {
      toastNotification.value = {
        type: 'error',
        title: 'Failed to initialise Moonpay',
        content: 'Please try again later',
        closeAfter: 5000,
      };
    } finally {
      await promiseTimeout(1000);
      isMoonPayLoading.value = false;
    }
  }

  return {
    isMoonPayLoading,

    handleStartMoonpayPayment,
    fetchMoonpayFiatCurrencies,
    fetchMoonpayCryptoCurrencies,
    fetchMoonpayBuyFiatQuote,
  };
}
