<template>
  <div class="web3-upsell">
    <ButtonButton
      v-if="wallet"
      theme="grey-dark"
      size="sm"
      class="w-full"
      @click.prevent="emits('switch-to-swap-tab')"
    >
      <div class="text-green-bright">
        Switch to Web3 Wallet
      </div>
    </ButtonButton>
    <div v-else class="connect-wallet bg-slate-900 p-3 rounded-md">
      <ModalConnectWallet
        :web-data="webData"
        :is-loading="isLoadingProviders"
        :vertical="vertical"
        web3-only
        hide-connect
        :connect-from-email="connectFromEmail"
        @on-login="onLogin"
      />
      <div v-if="!vertical" class="reasons text-center">
        <p class="text-white">Upgrade to our premium experience</p>
        <p class="text-sm mt-1 mb-3 text-slate-light">
          <span v-for="reason in connectWeb3Reasons" :key="reason" class="mx-1">
            <SvgIcon
              icon="tick"
              width="10"
              height="10"
              class="text-blue-500"
            />
            {{ reason }}
          </span>
        </p>
      </div>
      <ButtonButton
        v-if="!vertical"
        class="w-full hover:bg-slate-700"
        theme="grey-light"
        size="sm"
        @click.prevent="toggleVertical"
      >
        Connect
      </ButtonButton>
    </div>
  </div>
</template>

<script setup>
defineProps({
  webData: {
    type: Object,
    required: true,
  },
  isLoadingProviders: {
    type: Boolean,
    default: false,
  },
  connectFromEmail: {
    type: Boolean,
    default: false,
  },
});

const authStore = useAuthStore();
const {
  wallet,
} = storeToRefs(authStore);

const vertical = ref(false);
const connectWeb3Reasons = ref(['Fast', 'Frictionless', 'Web3',]);

const emits = defineEmits(['switch-to-swap-tab', 'on-login',]);

function toggleVertical() {
  vertical.value = true;
}

function onLogin(provider) {
  emits('on-login', provider);
}
</script>
