<template>
  <div class="rounded-md bg-slate-900/90 backdrop-blur-sm font-bold z-20 flex flex-col justify-center items-center text-center px-6 text-sm">
    <img
      src="~/assets/img/misc/cyber-chest-blue-open-with-diamonds.png"
      width="120"
      class="mb-2"
    >
    <h4 class="text-xl mb-2">
      Pending Reward
    </h4>
    <p class="mb-4">
      You cannot withdraw while you have a pending reward on your account. Please <span class="text-cyan-500">Complete</span> or
        <span class="text-cyan-500">Cancel</span> the reward to
        enable withdrawal.
    </p>
    <ButtonButton
      theme="primary"
      @click.prevent="handleRedirectToRewards"
    >
      Go to Rewards
    </ButtonButton>
  </div>
</template>

<script setup>
const emits = defineEmits([
  'close',
]);

const router = useRouter();

function handleRedirectToRewards() {
  emits('close');
  return router.push({ path: '/notifications', query: { tab: 'rewards', }, });
}
</script>
