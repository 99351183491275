<template>
  <ModalMainNew
    transition-name="modal-slide-up"
    media-type="tx"
    class="relative"
    @close="close"
  >
    <div v-if="hasUserDeposited" class="tabs flex sticky top-0 w-full bg-[#252831] z-100">
      <div
        v-for="tab in tabs"
        :key="tab.label"
        class="tab flex-1 flex flex-wrap w-full relative text-slate-400 cursor-pointer border-b-2 border-transparent"
        :class="[
          { 'text-white border-b-2' : tab.isActive },
          tab.isActive ? (activeSubTab === 'web3' ? '!border-blue-500' : '!border-orange-500') : '',
        ]"
        @click.prevent.stop="changeTab(tab.label)"
      >
        <div class="py-4 text-center w-full" :class="tab.isActive ? (activeSubTab === 'web3' ? 'bg-blue-radial' : 'bg-orange-radial') : ''">
          <h1 class="font-bold text-xl">{{ tab.label }}</h1>
        </div>
      </div>
    </div>
    <div v-if="hasUserDeposited" class="content py-4 px-6 flex flex-col gap-4 overflow-y-scroll scrollbar-hide">
      <WalletMethodSelector :options="methodOptions" @select="handleMethodChange"/>
      <template v-if="activeSubTab === 'web3'">
        <WalletWeb3Wallet
          v-if="!wallet && connectingWallet"
          :web-data="web3Providers"
          :is-loading-providers="isLoadingProviders"
          web3-only
          hide-connect
          @on-login="handleLogin"
          @switch-to-swap-tab="handleSwitchToSwapTab"
        />
        <WalletWeb3Transfer
          v-else
          :tab="tabs[0].isActive ? walletModalModeTypes[0] : walletModalModeTypes[1]"
          :deposit-code="txModalInfo.depositCode"
          @change-tab="changeTab"
          @close="close"
        />
        <WalletNoWalletSwitch v-if="!wallet" @select="handleMethodChange('custodial')"/>
        <PaymentsBanner
          v-if="tabs[0].isActive"
          small
          is-emit
          @select="handleMethodChange('cards')"
        />
      </template>
      <template v-else-if="activeSubTab === 'custodial'">
        <WalletQRCode
          :tab="tabs[0].isActive ? walletModalModeTypes[0] : walletModalModeTypes[1]"
          @close="close"
        />
        <RewardAppliedCode
          v-if="tabs[0].isActive"
          source="Deposit"
          :code="txModalInfo.depositCode"
        />
        <PaymentsBanner
          v-if="tabs[0].isActive"
          small
          is-emit
          @select="handleMethodChange('cards')"
        />
      </template>
      <template v-else-if="activeSubTab === 'cards'">
        <WalletCardPayment @select="handleDepositViaCrypto"/>
      </template>

      <WalletTransactionHistoryLink/>
    </div>
    <div v-else class="content py-4 px-6 flex flex-col gap-4 overflow-y-scroll scrollbar-hide">
      <WalletFirstDepositOptions @select="handleSelectFirstDepositOption"/>
    </div>
    <div class="sticky order-last w-full h-6 left-0 bottom-0 bg-gradient-to-t to-transparent from-slate-500 pointer-events-none"/>
  </ModalMainNew>
</template>

<script setup>
import { walletModalModeTypes } from '@/types/Wallet';

const uiStore = useUiStore();
const {
  connectNoLogin,
  showWalletModal,
  walletModalFundsContext,
  txModalInfo,
  connectFromEmail,
} = storeToRefs(uiStore);
const {
  openAddAliasModal,
} = uiStore;

const userStore = useUserStore();
const {
  userData,
} = storeToRefs(userStore);

const authStore = useAuthStore();
const {
  attach,
  wallet,
} = storeToRefs(authStore);
const {
  attachWallet,
  loginWallet,
} = authStore;

const { $api, $rollbar, } = useNuxtApp();

const configProviders = getConfigSplit('AUTH_PROVIDERS');
const activeSubTab = ref('web3');
const tabs = ref([
  {
    label: 'Deposit',
    isActive: true,
  },
  {
    label: 'Withdraw',
    isActive: false,
  },
]);
const providers = ref([
  {
    label: 'MetaMask',
    loading: false,
    icon: 'metamaskNoBg',
    type: 'Web3',
    isLarge: true,
    chain: 'Ethereum',
  },
  {
    label: 'CoinBase',
    loading: false,
    icon: 'coinbaseNoBg',
    type: 'Web3',
    isLarge: false,
    chain: 'Ethereum',
  },
  {
    label: 'WalletConnect',
    loading: false,
    icon: 'walletconnectNoBg',
    type: 'Web3',
    isLarge: false,
    chain: 'Ethereum',
  },
  {
    label: 'Phantom',
    loading: false,
    icon: 'phantomNoBg',
    type: 'Web3',
    isLarge: false,
    chain: 'Solana',
  },
]);

const connectingWallet = ref(true);

const activeProvider = computed(() => {
  return providers.value.filter(provider => configProviders.includes(provider.label.toLowerCase()));
});
const web3Providers = computed(() => {
  return providers.value
  .filter((provider) => {
    return userData.value?.aliases ? (!!userData.value?.aliases?.SolanaAddress && provider.chain === 'Solana') || (!!userData.value?.aliases?.EthereumAddress && provider.chain === 'Ethereum') : true;
  })
  .filter(provider => activeProvider.value.find(v => v.label === provider.label));
});
const isLoadingProviders = computed(() => !!providers.value.some(provider => provider.loading));
const userHasAddress = computed(() => !!userData.value?.aliases?.EthereumAddress || !!userData.value?.aliases?.SolanaAddress);

const hasUserDeposited = ref(false);

const methodOptions = computed(() => [
  {
    label: 'Web3 Wallet',
    value: 'web3',
    isActive: activeSubTab.value === 'web3',
    hide: false,
  },
  {
    label: 'Transfer Crypto',
    value: 'custodial',
    isActive: activeSubTab.value === 'custodial',
    hide: false,
  },
  {
    label: 'Card Deposit',
    value: 'cards',
    isActive: activeSubTab.value === 'cards',
    hide: tabs.value[1].isActive,
  },
]);

function handleSelectSubTab(method) {
  methodOptions.value.forEach((option) => {
    option.isActive = option.value === method;
  });

  walletModalFundsContext.value = method === 'web3' ? 'Default' : 'Custodial';
}

function changeTab(tabLabel) {
  tabs.value.forEach((el) => {
    el.isActive = el.label === tabLabel;
  });

  if (tabs.value[1].isActive && activeSubTab.value === 'cards') {
    activeSubTab.value = 'custodial';
  }
}

function close() {
  showWalletModal.value = false;
  txModalInfo.value.mode = walletModalModeTypes[0];
  txModalInfo.value.depositCode = null;
}

function handleMethodChange(subTab) {
  activeSubTab.value = subTab;
  handleSelectSubTab(activeSubTab.value);
}

async function handleLogin(provider) {
  connectFromEmail.value = true;
  providers.value.forEach((prov) => {
    prov.loading = prov.label === provider;
  });
  const selected = providers.value.find(prov => prov.label === provider);

  if (attach.value) {
    await attachWallet(provider, connectNoLogin.value);
  } else {
    await loginWallet(provider, connectNoLogin.value, false);
  }

  if (wallet.value && connectFromEmail.value && !userHasAddress.value) {
    const aliasType = selected?.chain === 'Solana' ? 'SolanaAddress' : 'EthereumAddress';
    openAddAliasModal(aliasType, 2, 'swap');
    close();
    return;
  }

  changeTab(tabs.value[0].label);

  providers.value.forEach((prov) => {
    prov.loading = false;
  });

  connectingWallet.value = false;
}

function handleSwitchToSwapTab() {
  changeTab(tabs.value[0].label);
}

function handleSelectFirstDepositOption(subTab) {
  hasUserDeposited.value = true;
  handleMethodChange(subTab);
}

function handleDepositViaCrypto() {
  handleMethodChange(wallet.value ? 'web3' : 'custodial');
}

/**
 * TODO: Refactor when payment stats endpoints have been created
 * @Hipkiss 31st July 2024
 */
async function fetchHasUserDeposited() {
  try {
    const res = await $api('/user/participation', { method: 'GET', });
    hasUserDeposited.value = res.hasDeposited;
  } catch {
    $rollbar.error('fetchHasUserDeposited Failed:::', err);
  }
}

await fetchHasUserDeposited();

changeTab(txModalInfo.value.mode);
handleMethodChange(txModalInfo.value.subMode);
</script>
<style lang="scss" scoped>
.tabs {
  box-shadow: 0 4px 20px -10px black;
}
</style>
