<template>
  <div
    v-if="
      !isLoading && !isWithdraw
      || (withdrawalDetails?.outcome === 'WithdrawalLimitReached' && !hasRemainingWithdrawalLimit)
      || ((isLoading && !isWithdrawalComplete) || withdrawalDetails?.outcome === 'MaximumPendingWithdrawals' || isWithdrawalProcessing)
      || (!canUserWithdraw || withdrawalDetails?.outcome === 'NotPermitted')
      || (withdrawalDetails?.outcome !== 'Available')
      || errorMessage
    "
    class="messages text-sm text-slate-100 text-center mt-4"
  >
    <div
      v-if="!isLoading && !isWithdraw"
      class="text-center text-sm leading-tight text-slate-100 mb-2"
    >
      <p v-if="(hasFee && withdrawalDetails.outcome === 'Available' && Number(transferAmount) > 0)">
        {{ feeValue }} {{ selectedCurrency?.code }} Gas Fee applies.
      </p>
      <p v-else-if="flatFee">
        {{ withdrawalDetails.fee.configuration.amount }} {{ selectedCurrency?.code }} Gas Fee applies.
      </p>
      <p v-else-if="percentageFee">
        Gas Fee of {{ withdrawalDetails.fee.configuration.amount }}% {{ selectedCurrency?.code }} up to {{ withdrawalDetails.fee.configuration.maxAmount }} {{ selectedCurrency?.code }} applies.
      </p>
    </div>
    <!-- <p v-if="withdrawalDetails?.outcome === 'WithdrawalLimitReached' && !hasRemainingWithdrawalLimit">
      You have already withdrawn the maximum amount allowed for a {{ withdrawalLimitTime }}. You can withdraw another {{ withdrawalDetails?.limitRemainingAfterExpiry }} {{ selectedCurrency?.code }} <CountdownMain :time="withdrawalDetails?.limitExpiryTime" @ended="emits('checkIsWithdrawalAvailable')"/>
    </p> -->
    <p v-if="withdrawalDetails?.outcome === 'WithdrawalLimitReached' && !hasRemainingWithdrawalLimit">
      You have already withdrawn the maximum amount allowed for today. Please try again in 24 hours.
    </p>
    <div
      v-if="(isLoading && !isWithdrawalComplete) || withdrawalDetails?.outcome === 'MaximumPendingWithdrawals' || isWithdrawalProcessing"
      class="animate-pulsing flex justify-center items-center text-white mb-2"
    >
      <span v-if="!isLoading && withdrawalDetails?.outcome === 'MaximumPendingWithdrawals' && !!withdrawalDetails?.requireConfirm" class="inline-block">
        Check your email to confirm your withdrawal request.
      </span>
      <span v-else-if="isWithdrawalProcessing" class="inline-block">
        Pending Withdrawal
      </span>
      <a
        v-if="txUrl"
        :href="txUrl"
        class="flex items-center ml-1 uppercase"
        target="_blank"
      >
        <span class="max-w-[60px] text-ellipsis overflow-hidden inline-block">{{ txHash }}</span>
        <span class="icon-ico-link-external relative text-sm"/>
      </a>
    </div>

    <p v-if="!canUserWithdraw || withdrawalDetails?.outcome === 'NotPermitted'" class="mb-3">
      Unfortunately you are unable to withdraw at this time.
    </p>
    <p v-if="withdrawalDetails?.outcome === 'MinimumDepositlNotMet'" class="mb-3 text-red">
      {{ withdrawalDetails?.detail }}
    </p>
    <p v-if="withdrawalDetails?.outcome === 'VerificationRequired'" class="mb-3 text-red">
      Account is waiting for KYC docs to process this withdrawal. Please check your email to complete this request.
    </p>
    <p v-if="withdrawalDetails?.outcome === 'DepositRequirementNotMet'" class="mb-3 text-red">
      You must have made a deposit to one of your crypto balances before you can withdraw.
    </p>
    <p v-else-if="errorMessage && !['WithdrawalLimitReached', 'PendingRewardRestriction'].includes(withdrawalDetails?.outcome)" class="text-red mb-2">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script setup>
import BigNumber from 'bignumber.js';

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: null,
  },
  selectedCurrency: {
    type: Object,
    default: null,
  },
  txHash: {
    type: String,
    default: null,
  },
  network: {
    type: String,
    default: null,
  },
  canUserWithdraw: {
    type: Boolean,
    default: true,
  },
  withdrawalDetails: {
    type: Object,
    default: null,
  },
  transferAmount: {
    type: [Number, String,],
    default: 0,
  },
  isWithdraw: {
    type: Boolean,
    default: false,
  },
  isWithdrawalComplete: {
    type: Boolean,
    default: false,
  },
  isWithdrawalProcessing: {
    type: Boolean,
    default: false,
  },
});

// const emits = defineEmits([
//   'checkIsWithdrawalAvailable',
// ]);

const { getExplorerUrl, } = useBlockchainConfigStore();

const txUrl = computed(() => props.txHash ? getExplorerUrl(props.network, 'transaction', props.txHash) : null);
const hasFee = computed(() => !!props.withdrawalDetails?.fee && props.withdrawalDetails.fee?.amount > 0);
const flatFee = computed(() => !!props.withdrawalDetails?.fee && props.withdrawalDetails.fee.configuration.type === 'Flat');
const percentageFee = computed(() => !!props.withdrawalDetails?.fee && props.withdrawalDetails.fee.configuration.type === 'Percentage');
const feeValue = computed(() => props.withdrawalDetails?.fee ? new BigNumber(props.withdrawalDetails.fee.amount) : 0);
const hasRemainingWithdrawalLimit = computed(() => props.withdrawalDetails?.limitRemaining ? !new BigNumber(props.withdrawalDetails.limitRemaining).isZero() : false);
// const withdrawalLimitTime = computed(() => {
//   if (props.withdrawalDetails.isMonthlyLimit) {
//     return '30 day period';
//   }

//   return props.withdrawalDetails.limitPeriodInHours ? `${props.withdrawalDetails.limitPeriodInHours} hour period` : null
// });
</script>
