<template>
  <div class="section-no-wallet flex flex-col gap-4">
    <DropdownCurrency :update="updateCurrency" @select="handleCurrencyChange"/>
    <div v-if="networkOptions.length > 1 && activeNetworkOption && !isLoading && ((tab === walletModalModeTypes[0] && !isDepositRestricted) || tab === walletModalModeTypes[1] && !isWithdrawalRestricted)" class="dropdown mb-4 -mt-1">
      <div class="text-3xs font-bold mb-2 text-white ml-[1px]">Choose Network</div>
      <DropdownMain
        :options="networkOptions"
        :active-option="activeNetworkOption"
        theme="input-normal"
        full
        is-tx-modal
        @select="handleUpdateNetworkOption"
      >
        <div class="flex items-center">
          <SvgIcon
            :icon="activeNetworkOption.icon"
            width="24"
            height="24"
            class="my-auto"
          />
          <span class="mx-2">
            {{ activeNetworkOption.text }}
          </span>
        </div>
      </DropdownMain>
    </div>
    <div v-if="isDepositRestricted" class="h-[184px] flex items-center justify-center font-bold text-sm rounded-lg bg-black/50 px-5 text-center w-full mb-3">
      Sorry, deposits are not available in your location.
    </div>
    <div v-else-if="isLoading" class="h-[184px] flex items-center justify-center">
      <Spinner :has-logo="false" size="xs"/>
    </div>
    <div v-else-if="tab === walletModalModeTypes[0]" class="bg-slate-900 flex rounded-md p-4 flex-col">
      <div class="message text-xs">
        <WalletMessageWarning
          v-if="!!selectedCurrency && !!activeNetworkOption"
          :currency-code="selectedCurrency?.baseCode || selectedCurrency?.code"
          :network="activeNetworkOption.text"
        />
      </div>
      <VueQrcode
        v-if="address && dataUrl"
        :value="dataUrl"
        type="image/png"
        :color="{ dark: '#1475E1', light: '#25283100' }"
        class="w-[calc(50%)] h-[calc(50%)] rounded-md animate-fade-in mx-auto"
      />
      <div class="address">
        <div class="label text-sm text-slate-200">
          Address
        </div>
        <div class="address-output text-white text-sm mb-3 mt-1 whitespace-nowrap text-ellipsis overflow-hidden">
          {{ address }}
        </div>
        <button
          type="button"
          class="bg-slate-800 py-3 w-full px-5 rounded-md my-auto hover:bg-[#5F677C]"
          @click.prevent.stop="copy(address)"
        >
          {{ copyAddressMessage }}
        </button>
      </div>
      <Border/>
      <div class="network">
        <div class="label text-sm text-slate-200">
          Network
        </div>
        <div class="address-output text-white">
          {{ activeNetworkOption?.text }}
        </div>
      </div>
      <Border/>
      <div class="safety-messages text-gray-400 mt-2 text-xs">
        <div v-if="minDeposit">
          Deposits less than <span class="font-bold text-gray-200">{{ Number(minDeposit) * Number(selectedCurrency?.factor) }} {{ selectedCurrency?.baseCode || selectedCurrency?.code }}</span> will <span class="font-bold text-red-600">NOT</span> be credited to your balance.
        </div>
        <div v-if="selectedCurrency?.factor !== 1" class="mt-2">
          <span class="font-bold text-gray-200">1 {{ selectedCurrency?.code }}</span> will be credited for every <span class="font-bold text-gray-200">{{ selectedCurrency?.factor }} {{ selectedCurrency?.baseCode || selectedCurrency?.code }}</span> deposited.
        </div>
        <p v-if="selectedCurrency?.code === 'BTC'" class="mt-2 font-bold text-orange-500">
          Bitcoin deposits can take some time to process on the blockchain. You can check its status on your
          <NuxtLink to="/wallet?view=Transactions" class="text-white">
            transactions
          </NuxtLink>
          page.
        </p>
      </div>
    </div>
    <template v-else>
      <WalletCustodialWithdraw
        v-if="!!selectedCurrency"
        :selected-currency="selectedCurrency"
        :active-network-option="activeNetworkOption"
        :current-balance="currentBalance"
        @close="emits('close')"
      />
    </template>
  </div>
</template>
<script setup>
import VueQrcode from 'vue-qrcode';
import { useClipboard, useTimeout } from '@vueuse/core';

import { walletModalModeTypes } from '@/types/Wallet';

import { useWallet } from '~/composables/useWallet';

const props = defineProps({
  tab: {
    type: String,
    default: '',
  },
});

const emits = defineEmits([
  'close',
]);

const depositAccountStore = useDepositAccountStore();
const {
  init: initDepositAccountStore,
} = depositAccountStore;
const {
  accounts,
} = storeToRefs(depositAccountStore);

const uiStore = useUiStore();
const {
  walletModalFundsContext,
} = storeToRefs(uiStore);

const locationStore = useLocationStore();
const {
  isDepositRestricted,
  isWithdrawalRestricted,
} = storeToRefs(locationStore);

const bankingStore = useBankingStore();
const {
  balances,
} = storeToRefs(bankingStore);

const authStore = useAuthStore();
const {
  wallet,
} = storeToRefs(authStore);

const blockchainConfigStore = useBlockchainConfigStore();
const {
  getNetworkName,
} = blockchainConfigStore;

const {
  fetchCryptoCurrencyAddress,
} = useWallet();

if (!accounts.value.length) {
  await initDepositAccountStore();
}

const selectedCurrency = ref(null);
const isLoading = ref(false);
const dataUrl = ref('');
const address = ref('');
const errorMessage = ref('');
const activeNetworkOption = ref();
const updateCurrency = ref(false);

const currentBalance = computed(() => balances.value.find(item => item.name === selectedCurrency.value?.code && item.context === walletModalFundsContext.value));
const minDeposit = computed(() => !currentBalance.value ? 0 : currentBalance.value.config.deposit.minAmount);
const networkOptions = computed(() => {
  if (!currentBalance.value) { return []; }

  return Object.entries(currentBalance.value.config?.networks)
  .filter(([, value,]) => !value.contexts || value.contexts.includes(walletModalFundsContext.value))
  .filter(([key,]) =>
    walletModalFundsContext.value === 'Custodial'
    || !wallet.value
    || (key === 'Solana' && wallet.value.network === 'Solana')
    || (key !== 'Solana' && wallet.value.network !== 'Solana')
  )
  .map(([key,]) => {
    return {
      name: key,
      text: getNetworkName(key),
      icon: key,
    };
  });
});

const { copy, copied, } = useClipboard({ source: props.address, legacy: true, });

const copyAddressMessage = computed(() => {
  const defaultMessage = `Copy ${selectedCurrency.value?.baseCode || selectedCurrency.value?.code} Address`;
  if (copied.value) {
    useTimeout(2000, {
      callback: () => {
        return defaultMessage;
      },
    });
    return 'Copied!';
  }

  return defaultMessage;
});

async function handleCurrencyChange(currency) {
  selectedCurrency.value = currency;
  activeNetworkOption.value = networkOptions.value[0];
  errorMessage.value = '';
  address.value = '';
  dataUrl.value = '';
  isLoading.value = true;
  const response = await fetchCryptoCurrencyAddress(currentBalance.value.name, currentBalance.value.config.defaultNetwork);

  if (!response.error) {
    address.value = response.address;
    dataUrl.value = response.dataUrl;
  } else {
    errorMessage.value = response.error;
  }
  isLoading.value = false;
}

async function handleUpdateNetworkOption(option) {
  if (option.name !== activeNetworkOption.value?.name) {
    activeNetworkOption.value = {
      name: option.name,
      text: getNetworkName(option.name),
      icon: option.name,
    };

    if (props.tab === walletModalModeTypes[0] && !isDepositRestricted.value) {
      errorMessage.value = '';
      address.value = '';
      dataUrl.value = '';
      isLoading.value = true;
      const response = await fetchCryptoCurrencyAddress(currentBalance.value.name, option.name);

      if (!response.error) {
        address.value = response.address;
        dataUrl.value = response.dataUrl;
      } else {
        errorMessage.value = response.error;
      }
      isLoading.value = false;
    }
  }
}
</script>
